/* eslint-disable */
import React, {useEffect, useLayoutEffect, useRef} from 'react';
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import {rankApps, RankFilters} from "../../../api/decisions.api";
import {getRandomItem} from "../../../utils";

// const APP_ID = 352;
// const API_TOKEN = '636901c7877c4a686e2d0f1249f5a009c700f6f2';
//const API_URL = 'https://module3.dev.sparkdit.com';
// const API_URL = 'http://localhost:8002';


// Backend 1: Intent Extraction
const INTENT_API_URL = 'https://maria-backend.dev.sparkdit.com/intentextraction';
// Backend 2: Generating Questions
const QUESTIONS_API_URL = 'https://maria-backend.dev.sparkdit.com/generatingquestions';

const positiveReactions = [
    'Okay!',
    'I see!',
    'Very well!',
    'Noted!',
    '',
];

const finalResultReactions = [
    'Okay! Here we go! These are the top 3 results based on your preferences',
    'Alright, I’ve got a few suggestions for you!',
    'Based on what you’ve shared, here are some great options.',
	'I couldn’t settle on just one, so here’s a shortlist for you!',
    'Here are a couple of recommendations I think you’ll like.',
	'I’ve narrowed it down to these options—let’s see what you think!',
    // 'Good news! You’ve got options—lucky you!',
	// 'Not one, but a couple of gems coming your way!',
	// 'Why pick one when I can dazzle you with a few?',
    // 'I couldn’t stop at one—so here’s the whole buffet!',
	// 'Here’s the top-tier lineup—pick your favorite!'
];

const nonPositiveReactions = [
    ''
];

// const loadingQuestionsReactions = [
//     'Okay! Let me think about it!',
//     'Interesting! I like the way you think!',
//     'Ha ha ha! Is it really what you care about? Joking! Give me a second...',
//     'Perfect! I will ask you couple of more questions.'
// ];

const transformCriteria = (criteria: RankFilters['criteria']): RankFilters['criteria'] => {
    // @ts-ignore
    return criteria.map(item => ({
        ...item,
        // @ts-ignore
        value: item.value === '' ? '-' : item.value
    }));
};

const Questionnaire = ({ appId, readyToStart }: { appId: number; readyToStart: boolean }) => {
    const navigate = useNavigate();
    const currentQuestion = useRef<{
        question: string,
        repeat: string,
        list_input: string[],
        criteria_type: string,
        criteria: string|null
    }>(); 
    const currentQuestionIndex = useRef(0);
    const questions = useRef([]);
    const isRetry = useRef(false);
    const isFirstQuestion = useRef(true);
    const answeredText = useRef('');
    // const scoreCriteriaRef = useRef(null);
    const rankRef = useRef<{} | null>(null);
    const initialRankRef = useRef<{} | null>(null);
    const isRankLoading = useRef(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const score = useRef();

    const onGetQuestionAnswer = async (event: CustomEvent) => {
        const {answer} = event.detail;

        answeredText.current = answer;
        handleQuestionResponse();
    };

    // this function is used to get the 'rank' and save it to a constant RankData,
    // which will be updated as we go over the questions and then use it as input to get the results calling Sparkdit apis
    const getRank = async () => {
        try {
            const response = await axios.get(`${QUESTIONS_API_URL}/rank/${appId}`);
            if (response.status === 200) {
                console.log('Rank data:', response.data);
                return response.data;
            } else {
                console.warn('Unexpected response status:', response.status);
                return null;
            }
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            return null;
        }
    };

    const fetchRank = async () => {
        isRankLoading.current = true;
        const response = await getRank();
        if (response) {
            initialRankRef.current = response;
            rankRef.current = response;
        }
    };

    useLayoutEffect(() => {
        if (!isRankLoading.current) {
            fetchRank();
        }
    }, []);

    const checkIntentAndUpdateRank = async (
        text: string,
        rank: any,
        criteria: string | null,
        list_input: string[],
        criteria_type: string,
        question: string
    ) => {
        try {
            let payload;

            if (criteria_type === 'tradeoff') {
                payload = {
                    text,
                    ra: rank || {},
                    criteria: null,
                    list_input,
                    criteria_type,
                    question
                };
            } else {
                payload = {
                    text,
                    ra: rank || {},
                    criteria,
                    list_input,
                    criteria_type,
                    question
                };
            }
    
            // Make the unified call to `check-intent`
            const response = await axios.post(`${INTENT_API_URL}/check-intent/`, payload);
            console.log(response, "RESPONSE");
            
            // If the API returns false, we know the intent was not understood
            if (response.data.is_related === false) {
                return false;
            }
    
            // Otherwise, update rank with the new rank data
            rankRef.current = response.data.updated_rank;  // Updating the rank reference
            console.log('Rank updated successfully:', rankRef.current);
    
            // If criteria_type is 'tradeoff', set the score value
            if (criteria_type === 'tradeoff') {
                score.current = response.data.score;  // Assigning a constant value
                console.log('Score set successfully:', score);
            }
    
            return true;  // The answer was valid
        } catch (error) {
            console.error('Error with the check-intent call:', error);
            return false;
        }
    };
    
    
    const loadMainQuestions_ = async () => {
        try {
            const res = await axios.post(`${QUESTIONS_API_URL}/generate-criteriabehavior-questions/`, {
                scores: score.current,
                app_id: appId
            });
            questions.current = res.data;
            // scoreCriteriaRef.current = res.data.questions[1];
            isFirstQuestion.current = false;
            return true;
        } catch (error) {
            console.error('Error loading main questions:', error);
            return false;
        }
    };
    

    const navigateToAppPage = () => {
        // fire an event to virtual agent to tell she didn't get the response
        document.dispatchEvent(new CustomEvent('virtual-agent-read', {
            detail: {
                text: 'Sorry! It seems I\'m not able to understand your response. I will redirect you to the application page! See you next time!',
            }
        }));

        timeoutRef.current = setTimeout(() => {
            navigate(`/apps/${appId}`, { replace: false })
        }, 10000)
    }

    // const agentSpeak = (text: string) => {
    //     document.dispatchEvent(new CustomEvent('virtual-agent-read', {
    //         detail: {
    //             text,
    //         }
    //     }));
    // }

    const askCurrentQuestion = () => {
        // @ts-ignore
        let questionToAsk = currentQuestion.current.question;
        if (isRetry.current) {
            // @ts-ignore
            questionToAsk = currentQuestion.current.repeat
        }
        const reaction = isFirstQuestion.current || currentQuestionIndex.current === 1 ? ''
            : getRandomItem(isRetry.current ? nonPositiveReactions : positiveReactions);
        document.dispatchEvent(new CustomEvent('virtual-agent-read', {
            detail: {
                text: reaction + ' ' + questionToAsk,
                shouldListen: true,
                products: [],
                // @ts-ignore
                options: currentQuestion.current?.list_input || [],
                isFirstQuestion: isFirstQuestion.current,
            }
        }));
    };

    const renderTheResult = async () => {
        // @ts-ignore
        const {results} = await rankApps({id: appId, criteria: transformCriteria(rankRef.current.criteria)}, 5, 1);
        
        const resultsToShow = results.slice(0, 3);

        const names = resultsToShow.map((item: any) => item.data.name.value);
        const lastName = names.pop();
        const namesText = names.join(', ');

        const resultsText = `${namesText} and ${lastName}`;
        const finalReaction = getRandomItem(finalResultReactions);

        document.dispatchEvent(new CustomEvent('virtual-agent-read', {
            detail: {
                text: `${finalReaction} ${resultsText}`,
                products: resultsToShow,
                isLastQuestion: true,
            }
        }));
    }

    const handleQuestionResponse = async () => {
        let isAnswerClear = await checkIntentAndUpdateRank(
            answeredText.current, 
            rankRef.current, 
            currentQuestion.current?.criteria||'', 
            currentQuestion.current?.list_input||[], 
            currentQuestion.current?.criteria_type||'', 
            currentQuestion.current?.question||''
        );

        if (isAnswerClear) {
            if (isFirstQuestion.current) {
                // agentSpeak(getRandomItem(loadingQuestionsReactions));
                // @ts-ignore
                await loadMainQuestions_();

            } 
        }

        else {
            if (isRetry.current) {
                // asked 2 times again, but still didn't get the response
                return navigateToAppPage();
            }
            isRetry.current = true;
            answeredText.current = '';
            return askCurrentQuestion();
        }


        if (currentQuestionIndex.current < questions.current.length) {
            resetCurrentQuestionState();
            currentQuestion.current = questions.current[currentQuestionIndex.current++];
            askCurrentQuestion();
            return;
        }

        return renderTheResult();
    };

    useEffect(() => {
        if (readyToStart) {
            console.log('startTheSession ask the first question.');
            startTheSession();
        }
    }, [readyToStart])

    useEffect(() => {
        // @ts-ignore
        document.addEventListener('virtual-agent-recorded', onGetQuestionAnswer);

        return () => {
            // @ts-ignore
            document.removeEventListener('virtual-agent-recorded', onGetQuestionAnswer);
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        }
    }, []);

    const fetchFirstQuestion = async () => {
        try {
            const response = await axios.get(`${QUESTIONS_API_URL}/generate-tradeoff-questions/${appId}`, {

            });
            console.log(response, "first question")
            // @ts-ignore
            currentQuestion.current = {
                question: response.data[0].question,
                repeat: response.data[0].repeat,
                list_input: response.data[0].list_input,
                criteria_type: 'tradeoff',
                criteria: null
            };
        } catch (error) {
            console.error('Error fetching the first question:', error);
        }
    };

    const resetCurrentQuestionState = () => {
        currentQuestion.current = undefined;
        isRetry.current = false;
    };

    const startTheSession = () => {
        fetchFirstQuestion().then(() => {
            askCurrentQuestion();
        });
    };

    return (
        <></>
    );
};

export default Questionnaire;
