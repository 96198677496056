import React from 'react';
import {Button, Pagination} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import BasicTabs from "../../tabs/tabs";
import {AppSkinProps} from "../../../types/decisions";
import Modal from "../../modal/Modal";
import CriteriaSetting from "./CriteriaSettings";
import {TriangleSlider} from "../../slider";

const AppSkin3 = ({
  results,
  onRerank,
  criteriaLoading,
  Loading,
  criteriaData,
  onSliderChange,
  onResetTradeOffs,
  onCriteriaApply,
  resultLoading,
  pageInfo,
  onPageChange,
  onTradeoffValueChange,
  fieldInitialValues,
}: AppSkinProps) => {
  const [isImportanceOpen, setIsImportanceOpen] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [modalContent, setModalContent] = React.useState<React.ReactElement>(<></>);
  const handleClose = () => {
    setOpen(false);
    setModalContent(<></>);
  }

  const CriteriaSettings = () => {
    return (
      <div>
        {criteriaData.fields.map((field, index) => (
          <CriteriaSetting
                  key={field.id}
                  field={field}
                  index={index}
                  fieldInitialValues={fieldInitialValues}
                  onTradeoffValueChange={onTradeoffValueChange}
                  onSliderChange={onSliderChange}
                  handleModalClose={handleClose}
          />
        ))}
      </div>
    )
  }

  return (
    <div className='application'>
      {criteriaLoading ? (
        <Loading/>
      ) : (
        <>
          <div className='application-data'>
            <img src={criteriaData.icon} alt={criteriaData.name}/>
            <div className='app-info'>
              <h1 className='app-name'>{criteriaData.name}</h1>
              <div className='app-description'>{criteriaData.description}</div>
            </div>
          </div>
          <div className='application-details'>
            <div className='app-criteria'>
              {criteriaLoading ? (
                <Loading/>
              ) : (
                <div>
                  <BasicTabs
                    tabNames={[isImportanceOpen ? 'Importance' : 'Settings']}
                    tabs={[
                      <>
                        {isImportanceOpen ? criteriaData.fields.map(({
                          id,
                          name,
                          hide_importance
                        }, idx) => (
                          <div key={id}
                               className={`app-criteria-field ${hide_importance === '1' || hide_importance === 'true' ? 'hidden' : ''}`}>
                            <label>{typeof name !== 'object' ? name : name.value}
                              <TriangleSlider
                                name={id}
                                min={0}
                                max={100}
                                step={1}
                                value={+criteriaData.fields[idx].importance}
                                onChange={(e, value) => onSliderChange(value as number, idx)}
                                isEditable
                                onEditIconClick={() => {
                                  setOpen(true);
                                  setModalContent(
                                    <CriteriaSetting
                                      field={criteriaData.fields[idx]}
                                      index={idx}
                                      onSliderChange={onSliderChange}
                                      onTradeoffValueChange={onTradeoffValueChange}
                                      fieldInitialValues={fieldInitialValues}
                                      handleModalClose={handleClose}
                                      areCTAsDisplayed
                                    />
                                  )
                                }}
                              />
                            </label>
                          </div>
                        )) : (
                          <CriteriaSettings/>
                        )}
                      </>,
                    ]}
                  />
                  <div className='app-criteria-actions' style={{display: "block"}}>
                    <div style={{marginBottom: 20}}>
                      <Button
                        className='app-criteria-actions-action'
                        type='submit'
                        variant='outlined'
                        style={{marginRight: '4%', width: '48%'}}
                        onClick={(e) => {
                          e.preventDefault();
                          setIsImportanceOpen(isOpen => !isOpen)
                        }}
                      >
                        {isImportanceOpen ? 'Settings' : 'Importance'}
                      </Button>
                      <Button
                        className='app-criteria-actions-action'
                        variant='outlined'
                        type='button'
                        onClick={onResetTradeOffs}
                        style={{width: '48%'}}
                      >
                        Reset
                      </Button>
                    </div>
                    <div>
                      <Button
                        className='app-criteria-actions-action'
                        type='submit'
                        variant='contained'
                        style={{width: '100%'}}
                        onClick={onCriteriaApply}
                      >
                        Decide
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className='app-results'>
              {resultLoading ? (
                <Loading/>
              ) : (
                <>
                  <h3>Results ({pageInfo.total})</h3>
                  <div>
                    {results.length === 0 ? (
                     <div style={{ color: '#676767'}}>No products found.</div>
                    ): results.map((result, index) => {
                        // @ts-ignore
                        const productLink = result.data.productlink;
                        const productLinkUrl = productLink?.value || '';

                        return (
                            <div className='app-results-item' key={result.id} style={{ overflow: "hidden" }}>
                                <div className="image-container"
                                     style={{backgroundImage: `url('${result.icon}')`}}/>
                                <div className='app-criteria-item-details'>
                                    <div className='app-criteria-item-details-rank'>
                                        <div className='app-criteria-item-details-rank-bar'
                                             style={{width: result.rank + '%'}}/>
                                        <div className='app-criteria-item-details-rank-rate'>
                                            {result.rank === '-' ? '' : result.rank + '%'}
                                        </div>
                                    </div>
                                    <div>
                                        <h3
                                            className={`truncate ${productLinkUrl ? 'cursor-pointer' : ''}`}
                                            title={typeof result.data.name !== 'object' ? result.data.name : result.data.name.value}
                                            onClick={() => {
                                                if (productLinkUrl) {
                                                    window.open(productLink.value);
                                                }
                                            }}
                                        >
                                            {typeof result.data.name !== 'object' ? result.data.name : result.data.name.value}
                                        </h3>
                                        <div className='app-criteria-item-details-specs'>
                                            {Object.keys(result.data).map((key) => {
                                                if (!/^f\d+$/.test(key)) {
                                                    return null;
                                                }
                                                // @ts-ignore
                                                const {name, value} = result.data[key];
                                                return (
                                                        <div
                                                                className='app-criteria-item-details-specs-spec'
                                                                key={result.id + key}>
                                                            <span>{name}</span>
                                                            <span>
                                  {typeof value === 'object' ? (
                                          <>{Object.keys(value).map((key) => (
                                                  <span>{key} {value[key]} <br/></span>
                                          )) }</>
                                  ) : (
                                          value
                                  )}
                                </span>
                            </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className='app-criteria-item-details-ctas'>
                                        {index !== 0 && (
                                            <Button
                                                className='app-criteria-item-details-ctas-cta'
                                                variant='outlined'
                                                onClick={() => onRerank(result.id, true)}
                                                startIcon={<ArrowUpwardIcon/>}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                  </div>
                  <div className='app-results-page-info'>
                    {/*Displayed products: <b>{pageInfo.start}</b> to <b>{pageInfo.end}</b> of <b>{pageInfo.total}</b>.*/}
                    {results.length > 0 ? (
                      <Pagination
                        page={pageInfo.current_page}
                        count={pageInfo.total_pages}
                        shape="rounded"
                        onChange={(event, page) => onPageChange(page)}
                      />
                    ) : null}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
      <Modal isOpen={open} handleClose={handleClose}>
        {modalContent}
      </Modal>
    </div>
  )
};

export default AppSkin3;