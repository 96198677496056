import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {getDecisionCriteria, rankApps, rerankApps} from "../../api/decisions.api";
import {CircularProgress} from "@mui/material";
import { useMediaQuery } from 'react-responsive';
import AppSkin1 from "./skins/AppSkin1";
import AppSkin2 from "./skins/AppSkin2";
import AppSkin3 from "./skins/AppSkin3";
import AppSkin4 from "./skins/AppSkin4";
import AppSkin5 from "./skins/AppSkin5";
import AppSkin6 from "./skins/AppSkin6";
import MobileSkin1 from "./skins/mobile/MobileSkin1";
import {getEnvironmentVariable, transformCriteriaData} from "../../utils";
import {AppCriteria, AppCriteriaField, AppResultInfo, CriteriaConstraint, PageInfo} from "../../types/decisions";
import './application.scss';

const Loading = () => {
    return (
            <div style={{
                display: 'flex',
                height: '85vh',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <div style={{width: 'fit-content'}}>
                    <CircularProgress size={64}/>
                </div>
            </div>
    );
};

const allowedSkins = ['skin1', 'skin2', 'skin3', 'skin4', 'skin5', 'skin6'];

const initialAppCriteria: AppCriteria = {
    id: '',
    name: '',
    category: '',
    subcategory: '',
    config: {
        skin: 'skin0',
        rerank_engine: '',
    },
    Dataset: null,
    Datasets: [],
    date_created: '',
    date_modified: '',
    run_count: '',
    description: '',
    fields: [],
    technoFields: [],
    icon: '',
    ranking: '',
    user_id: 0,
    visibility: ''
};

const getCriteriaValueByType = (field: AppCriteriaField, newValue: any, key: string = 'initialKey') => {
    switch (field.type) {
        case 'text':
        case 'dynamic':
        case 'number':
            return newValue;
        case 'select':
        case 'bool':
            return {
                ...(field.val as Object),
                [key]: newValue
            }
        default:
            return newValue;
    }
};

const legacyAppUrl = getEnvironmentVariable('REACT_APP_LEGACY_APP_URL');

const Application = () => {
    const {id} = useParams<{ id: string }>();
    const [criteriaLoading, setCriteriaLoading] = useState<boolean>(true);
    const [resultLoading, setResultLoading] = useState<boolean>(true);
    const initialCriteriaData = useRef<AppCriteria>(initialAppCriteria);
    const [criteriaData, setCriteriaData] = useState<AppCriteria>(initialAppCriteria);
    const [criteriaConstraints, setCriteriaConstraints] = useState<{ [key: string]: CriteriaConstraint[] }>({});
    const [results, setResults] = useState<AppResultInfo[]>([]);
    const [pageInfo, setPageInfo] = useState<PageInfo>({
        page_size: 9,
        current_page: 1,
        start: 0,
        end: 0,
        total: 0,
        total_pages: 1,
    });
    const isSkinAllowed = allowedSkins.includes(criteriaData.config.skin);
    const isMobile = useMediaQuery({
        query: '(max-width: 768px)'
    });

    useLayoutEffect(() => {
        if (id) {
            getDecisionCriteria(id)
            .then(response => {
                initialCriteriaData.current = response;
                setCriteriaData(response);
            })
            .finally(() => setCriteriaLoading(false));
        }

    }, [id]);

    useEffect(() => {
        if (criteriaData.id && isSkinAllowed) {
            rankApps(transformCriteriaData(criteriaData, criteriaConstraints), pageInfo.page_size, pageInfo.current_page)
            .then(response => {
                setResults(response.results);
                setPageInfo({
                    current_page: response.current_page,
                    start: response.start,
                    end: response.end,
                    total: response.total,
                    total_pages: response.total_pages,
                    // page_size: response.page_size,
                    page_size: pageInfo.page_size,
                })
            })
            .finally(() => setResultLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [criteriaData.id]);

    const onCriteriaApply = () => {
        setResultLoading(true);
        rankApps(transformCriteriaData(criteriaData, criteriaConstraints), pageInfo.page_size, 1)
        .then(response => {
            setResults(response.results);
            setPageInfo({
                current_page: response.current_page,
                start: response.start,
                end: response.end,
                total: response.total,
                total_pages: response.total_pages,
                // page_size: response.page_size,
                page_size: pageInfo.page_size,
            })
        })
        .finally(() => setResultLoading(false));
    };

    const onRerank = (rerankProductId: string, scrollToTop: boolean = false) => {
        if (scrollToTop) {
            window.scrollTo({
                left: 0,
                top: 0,
                behavior: 'smooth',
            });
        }
        setResultLoading(true);
        rerankApps(transformCriteriaData(criteriaData, criteriaConstraints), rerankProductId, pageInfo.page_size, 1)
        .then(response => {
            setResults(response.results);
            setCriteriaData(criteriaData => ({
                ...criteriaData,
                fields: criteriaData.fields.map(field => ({
                    ...field,
                    importance: response.criteria[field.id],
                })),
            }));
            setPageInfo({
                current_page: response.current_page,
                start: response.start,
                end: response.end,
                total: response.total,
                total_pages: response.total_pages,
                // page_size: response.page_size,
                page_size: pageInfo.page_size,
            })
        })
        .finally(() => setResultLoading(false));
    };

    const onResetTradeOffs = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        event.preventDefault();
        if (JSON.stringify(criteriaData.fields) === JSON.stringify(initialCriteriaData.current.fields)) {
            return;
        }

        setCriteriaData({...initialCriteriaData.current});
        setResultLoading(true);
        rankApps(transformCriteriaData(initialCriteriaData.current, criteriaConstraints), pageInfo.page_size, pageInfo.current_page)
        .then(response => {
            setResults(response.results);
            setPageInfo({
                current_page: response.current_page,
                start: response.start,
                end: response.end,
                total: response.total,
                total_pages: response.total_pages,
                // page_size: response.page_size,
                page_size: pageInfo.page_size,
            })
        })
        .finally(() => setResultLoading(false));
    };

    const onSliderChange = (newValue: number, idx: number) => {
        const fields = criteriaData.fields;

        setCriteriaData(criteriaData => ({
            ...criteriaData,
            fields: [
                ...fields.slice(0, idx),
                {
                    ...fields[idx],
                    importance: newValue,
                },
                ...fields.slice(idx + 1),
            ]
        }));
    };

    const onSliderBulkChange = (newValues: { [key: string]: number}) => {
        setCriteriaData(criteriaData => ({
            ...criteriaData,
            fields: criteriaData.fields.map(field => {
                const newFieldValue = newValues[field.id];

                if (!newFieldValue) {
                    return {
                        ...field,
                        importance: 0,
                    }
                }

                return {
                    ...field,
                    importance: newFieldValue,
                }
            }),
        }));
    };

    const onTradeoffValueChange = (newValue: number | string, idx: number, key?: string) => {
        const fields = criteriaData.fields;

        setCriteriaData(criteriaData => {
            return {
                ...criteriaData,
                fields: [
                    ...fields.slice(0, idx),
                    {
                        ...fields[idx],
                        val: getCriteriaValueByType(fields[idx], newValue, key),
                    },
                    ...fields.slice(idx + 1),
                ]
            };
        })
    };

    const onPageChange = (page: number) => {
        setResultLoading(true);
        rankApps(transformCriteriaData(criteriaData, criteriaConstraints), pageInfo.page_size, page)
        .then(response => {
            setResults(response.results);
            setPageInfo({
                current_page: response.current_page,
                start: response.start,
                end: response.end,
                total: response.total,
                total_pages: response.total_pages,
                // page_size: response.page_size,
                page_size: pageInfo.page_size,
            })
        })
        .finally(() => setResultLoading(false));
    };

    if (!id || criteriaData.config.skin === 'skin0') {
        return <Loading/>;
    }

    if (!criteriaLoading && !isSkinAllowed) {
        return (
                <div className='application'>
                    <div className='warning-banner'>
                        This application is under construction. Visit <Link
                            to={`https://legacy2.sparkdit.com/decisions/view/${id}`} target='_blank'>the old
                        version</Link> of
                        the application.
                    </div>
                </div>
        );
    }

    const appSkins = {
        skin1: AppSkin1,
        skin2: AppSkin2,
        skin3: AppSkin3,
        skin4: AppSkin4,
        skin5: AppSkin5,
        skin6: AppSkin6,
    };

    let CurrentSkin = appSkins[criteriaData.config.skin];

    if (id === '523') {
        CurrentSkin = AppSkin6;
    }

    if (isMobile) {
        CurrentSkin = MobileSkin1;
    }

    return (
        <>
            <CurrentSkin
                id={id as string}
                results={results}
                criteriaData={criteriaData}
                criteriaConstraints={criteriaConstraints}
                setCriteriaConstraints={setCriteriaConstraints}
                onRerank={onRerank}
                Loading={Loading}
                onPageChange={onPageChange}
                criteriaLoading={criteriaLoading}
                onSliderChange={onSliderChange}
                onSliderBulkChange={onSliderBulkChange}
                onTradeoffValueChange={onTradeoffValueChange}
                onCriteriaApply={onCriteriaApply}
                onResetTradeOffs={onResetTradeOffs}
                fieldInitialValues={initialCriteriaData.current.fields}
                pageInfo={pageInfo}
                resultLoading={resultLoading}
            />
            <div className='old-app-description'>
                You are still able to <Link
                    to={`${legacyAppUrl}/decisions/view/${id}`}
                    target='_blank'>
                visit
            </Link> the old version.
            </div>
        </>
    );
};

export default Application;