import axios from "axios";
import {getEnvironmentVariable} from "../../utils";
import React from "react";
import {Buffer} from "buffer";

let silenceTimeout: NodeJS.Timeout | null = null;
const SILENCE_TIMEOUT = 4_000;
const heygenConfigs = {
    // apiKey: heygenKeys[0],
    serverUrl: getEnvironmentVariable('REACT_APP_VIRTUAL_AGENT_SERVER_URL'),
};

function monitorSilence(stream: MediaStream, state: string | undefined, audioContext: AudioContext | null, timeout: number = 0) {
    if (!audioContext) {
        return;
    }

    const analyser = audioContext.createAnalyser();

    const microphone = audioContext.createMediaStreamSource(stream);
    microphone.connect(analyser);

    analyser.fftSize = 512;
    const dataArray = new Uint8Array(analyser.fftSize);

    function checkForSilence() {
        analyser.getByteTimeDomainData(dataArray);

        let silenceDetected = true;
        for (let i = 0; i < dataArray.length; i++) {
            if (Math.abs(dataArray[i] - 128) > 10) {  // If signal is strong enough
                silenceDetected = false;
                break;
            }
        }

        // console.log('Silence detector works!');
        if (silenceDetected) {
            if (!silenceTimeout) {
                silenceTimeout = setTimeout(() => {
                    if (state === 'running') {
                        console.log('Stop listening');
                        // fire an event to stop
                        document.dispatchEvent(new CustomEvent('virtual-agent-stop-listening', {
                            detail: {
                                shouldFireRecordedEvent: true
                            }
                        }));
                    }
                }, timeout || SILENCE_TIMEOUT);
            }
        } else {
            silenceTimeout && clearTimeout(silenceTimeout);
            silenceTimeout = null;
        }

        requestAnimationFrame(checkForSilence);
    }

    checkForSilence();
}

// Function to create a new session
async function newSession(apiKey: string, quality: string, avatar_name: string, voice_id: string) {
    try {
        const response = await axios.post(`${heygenConfigs.serverUrl}/v1/streaming.new`, {
            quality,
            avatar_name,
            voice: {
                voice_id,
            },
        }, {
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': apiKey,
            },
        });
        return response.data.data;
    } catch (error) {
        console.error('Server error');
        return false
    }
}

// Function to start the session
async function startSession(apiKey: string, session_id: string, sdp: any) {
    try {
        const response = await fetch(`${heygenConfigs.serverUrl}/v1/streaming.start`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': apiKey,
            },
            body: JSON.stringify({session_id, sdp}),
        });
        console.log('startSession', response);
        return true;
    } catch (e) {
        console.error('Error', e);
        return false;
    }
}

// Function to handle ICE candidates
async function handleICE(
    apiKey: string,
    session_id: string,
    candidate: any,
    candidateIceInProgressTasksCount: React.MutableRefObject<number>,
    setAreCandidateIcesReady: React.Dispatch<React.SetStateAction<boolean>>
) {
    candidateIceInProgressTasksCount.current++;
    const response = await fetch(`${heygenConfigs.serverUrl}/v1/streaming.ice`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': apiKey,
        },
        body: JSON.stringify({session_id, candidate}),
    });

    if (--candidateIceInProgressTasksCount.current === 0) {
        setAreCandidateIcesReady(true);
    }

    if (response.status === 500) {
        throw new Error('Server error');
    } else {
        return await response.json();
    }
}

// Function to repeat the text
async function readText(apiKey: string, session_id: string, text: string) {
    const response = await fetch(`${heygenConfigs.serverUrl}/v1/streaming.task`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': apiKey,
        },
        body: JSON.stringify({session_id, text}),
    });
    if (response.status === 500) {
        console.error('Server error');
        throw new Error('Server error');
    } else {
        const data = await response.json();
        return data.data;
    }
}

// Function to stop the session
async function stopSession(apiKey: string, session_id: string) {
    const response = await fetch(`${heygenConfigs.serverUrl}/v1/streaming.stop`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': apiKey,
        },
        body: JSON.stringify({session_id}),
    });
    if (response.status === 500) {
        console.error('Server error');
        throw new Error('Server error');
    } else {
        const data = await response.json();
        return data.data;
    }
}

function renderCanvas(mediaElementRef: React.RefObject<HTMLVideoElement>, canvasElementRef: React.RefObject<HTMLCanvasElement>) {
    if (!mediaElementRef.current || !canvasElementRef.current) {
        return;
    }

    hideElement(mediaElementRef.current);
    showElement(canvasElementRef.current);

    canvasElementRef.current?.classList.add('show');

    const ctx = canvasElementRef.current.getContext('2d', {willReadFrequently: true});


    if (canvasElementRef.current.parentElement) {
        canvasElementRef.current.parentElement.style.background = '#ffffff';
    }

    function processFrame() {
        if (!mediaElementRef.current || !canvasElementRef.current) {
            return;
        }
        canvasElementRef.current.width = mediaElementRef.current.videoWidth;
        canvasElementRef.current.height = mediaElementRef.current.videoHeight;
        if (!ctx) {
            return;
        }

        ctx.drawImage(mediaElementRef.current, 0, 0, canvasElementRef.current.width, canvasElementRef.current.height);
        ctx.getContextAttributes().willReadFrequently = true;
        const imageData = ctx.getImageData(0, 0, canvasElementRef.current.width, canvasElementRef.current.height);
        const data = imageData.data;

        for (let i = 0; i < data.length; i += 4) {
            const red = data[i];
            const green = data[i + 1];
            const blue = data[i + 2];

            // You can implement your own logic here
            if (isCloseToGreen([red, green, blue])) {
                data[i + 3] = 0;
            }
        }

        ctx.putImageData(imageData, 0, 0);

        requestAnimationFrame(processFrame);
    }

    processFrame();
}

function isCloseToGreen(color: [number, number, number]) {
    const [red, green, blue] = color;
    return green > 90 && red < 90 && blue < 90;
}

function hideElement(element: HTMLElement | null) {
    if (!element) {
        return;
    }

    element.classList.add('hide');
    element.classList.remove('show');
}

function showElement(element: HTMLElement | null) {
    if (!element) {
        return;
    }

    element.classList.add('show');
    element.classList.remove('hide');
}

const encodePCMChunk = (chunk: any) => {
    const buffer = new ArrayBuffer(chunk.length * 2);
    const view = new DataView(buffer);
    chunk.forEach((sample: any, index: number) => {
        const value = Math.max(-1, Math.min(1, sample));
        view.setInt16(index * 2, value < 0 ? value * 0x8000 : value * 0x7fff, true);
    });
    return Buffer.from(buffer);
};

const getAudioStream = async function* (workletNode: any) {
    const audioQueue: any[] = [];
    workletNode.port.onmessage = (event: any) => {
        audioQueue.push(event.data);
    };

    while (true) {
        if (audioQueue.length > 0) {
            const chunk = audioQueue.shift();
            yield {AudioEvent: {AudioChunk: encodePCMChunk(chunk)}};
        } else {
            await new Promise((resolve) => setTimeout(resolve, 10));
        }
    }
};

export {
    monitorSilence,
    newSession,
    startSession,
    stopSession,
    handleICE,
    readText,
    renderCanvas,
    getAudioStream
};