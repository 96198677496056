import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {getEnvironmentVariable, getRandomItem} from "../../utils";
import HeadsetOffIcon from '@mui/icons-material/HeadsetOff';
// import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import process from "process";
import {Buffer} from "buffer";
import {
    LanguageCode,
    StartStreamTranscriptionCommand,
    TranscribeStreamingClient
} from "@aws-sdk/client-transcribe-streaming";
import Questionnaire from "./modules/Questionnaire";
import {useNavigate, useParams} from "react-router-dom";
import {getDecisionCriteria} from "../../api/decisions.api";
import {AppCriteria, AppResultInfo} from "../../types/decisions";
import {
    getAudioStream,
    handleICE,
    monitorSilence,
    newSession,
    readText,
    renderCanvas,
    startSession,
    stopSession
} from "./utilities";
import './virtual-agent.scss';
import './application.scss';
import 'animate.css';
import {Button} from "@mui/material";

window.Buffer = Buffer;
window.process = process;


// Replace these with your AWS credentials
const AWS_REGION = getEnvironmentVariable('REACT_APP_AWS_REGION');
const AWS_ACCESS_KEY_ID = getEnvironmentVariable('REACT_APP_AWS_ACCESS_KEY_ID');
const AWS_SECRET_ACCESS_KEY = getEnvironmentVariable('REACT_APP_AWS_SECRET_ACCESS_KEY');

let transcription = '';
let question = '';
const language = "en-US";
const SAMPLE_RATE = 44100;

const welcomeTexts = [
    'Hi there! I\'m Maria, your virtual assistant. Are you ready to start the journey?',
    'Hey! Maria here, your personal virtual agent. Let\'s begin our journey!',
    'Hello! I\'m Maria, your virtual helper. Are you ready to embark on our journey together?',
    'Hi! I\'m Maria, your go-to virtual assistant. Let\'s start this journey!',
    'Hey! I\'m Maria, your virtual agent. Ready to start the journey?'
];

let workletNode: any = undefined;
let audioContext: AudioContext | null = null;

const heygenKeysString = getEnvironmentVariable('REACT_APP_VIRTUAL_AGENT_KEYS');
const heygenKeys: string[] = heygenKeysString.split(';') || [''];
let NO_RESPONSE_DEFAULT_TIMEOUT = 20_000;

const VirtualAgent = () => {
    const navigate = useNavigate();
    const {id} = useParams<{ id: string }>();
    const isAppDataLoading = useRef(false);
    const [isBackgroundRemoved, setIsBackgroundRemoved] = useState(true);
    const avatarRef = useRef<HTMLInputElement>(null);
    const voiceRef = useRef<HTMLInputElement>(null);
    const sessionInfoRef = useRef<any>();
    const peerConnectionRef = useRef<any>();
    const mediaElementRef = useRef<HTMLVideoElement>(null);
    const canvasElementRef = useRef<HTMLCanvasElement>(null);
    const bgCheckboxWrapRef = useRef<HTMLDivElement>(null);

    const microphoneStream = useRef<MediaStreamAudioSourceNode | null>(null);
    const transcribeClient = useRef<TranscribeStreamingClient | null>(null);
    const [isMicrophoneAccessible, setIsMicrophoneAccessible] = useState(true);
    const isListeningInProgress = useRef(false);
    const [isAgentReady, setAgentIsReady] = useState(false);
    const [isReadyToStartTheSession, setReadyToStartTheSession] = useState(false);
    const [criteriaLoading, setCriteriaLoading] = useState<boolean>(true);
    const [appData, setAppData] = useState<AppCriteria | null>(null);
    const virtualAgentCreated = useRef(false);
    const isDecisionReady = !criteriaLoading && appData?.id;
    const [products, setProducts] = useState<AppResultInfo[]>([]);
    const [options, setOptions] = useState<string[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const candidateIceInProgressTasksCount = useRef(0);
    const [areCandidateIcesReady, setAreCandidateIcesReady] = useState(false);
    const heyGenApiKeyRef = useRef<string>('');
    const isFirstQuestion = useRef(true);
    const [isLastQuestionAsked, setIsLastQuestionAsked] = useState(false);
    const noResponseTimeoutRef = useRef<NodeJS.Timeout>();
    const [noResponseTimeout, setNoResponseTimeout] = useState<number>(NO_RESPONSE_DEFAULT_TIMEOUT);

    useLayoutEffect(() => {
        if (id && !isAppDataLoading.current) {
            isAppDataLoading.current = true;
            getDecisionCriteria(id)
                .then(response => {
                    setAppData(response);
                })
                .finally(() => setCriteriaLoading(false));
        }
    }, [id]);

    useEffect(() => {
        if (isAgentReady && isDecisionReady && areCandidateIcesReady) {
            setTimeout(() => {
                readText(
                    heyGenApiKeyRef.current,
                    sessionInfoRef.current?.session_id,
                    getRandomItem(welcomeTexts) + ' I will help you with' + (appData?.name || 'your questions.'),
                )
                .then(response => {
                    // start asking questions after intro text
                    setTimeout(() => setReadyToStartTheSession(true), (response && response.duration_ms) || 3000);
                });
            }, 3000);
        }

    }, [isAgentReady, isDecisionReady, appData?.name, areCandidateIcesReady]);

    // Create a new WebRTC session when the page loads
    async function createNewSession() {
        console.log('session is starting');

        // call the new interface to get the server's offer SDP and ICE server to create a new RTCPeerConnection
        sessionInfoRef.current = await createAgentNewSession();

        if (!sessionInfoRef.current) {
            return false;
        }
        const {sdp: serverSdp, ice_servers2: iceServers} = sessionInfoRef.current;

        if (!serverSdp || !iceServers) {
            return false;
        }

        // Create a new RTCPeerConnection
        peerConnectionRef.current = new RTCPeerConnection({iceServers: iceServers});

        // When audio and video streams are received, display them in the video element
        peerConnectionRef.current.ontrack = (event: any) => {
            // console.log('Received the track');
            if (event.track.kind === 'audio' || event.track.kind === 'video') {
                if (!mediaElementRef.current) {
                    return;
                }

                mediaElementRef.current.srcObject = event.streams[0];
            }
        };

        // When receiving a message, display it in the console
        peerConnectionRef.current.ondatachannel = (event: any) => {
            const dataChannel = event.channel;
            dataChannel.onmessage = () => {
                // console.log('message from peer connection', message);
            };
        };

        // Set server's SDP as remote description
        await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(serverSdp));

        console.log('Session creation completed');
        console.log('Now you can start the stream');
        return await startAndDisplaySession();
    }

    const renderProducts = (products: AppResultInfo[]) => {
        return (
            <div className='application'>
                <div className='application-details' style={{justifyContent: 'center'}}>
                    <div className='app-results'>
                        <div className='results-skin4' style={{margin: '32px auto'}}>
                            {products.map((result) => (
                                <div className='app-results-item' key={result.id}>
                                    <div className="image-container"
                                         style={{backgroundImage: `url('${result.icon}')`}}/>
                                    <div className='app-criteria-item-details'>
                                        <div className='app-criteria-item-details-rank'>
                                            <div className='app-criteria-item-details-rank-bar'
                                                 style={{width: result.rank + '%'}}/>
                                            <div className='app-criteria-item-details-rank-rate'>
                                                {result.rank === '-' ? <>&nbsp;</> : result.rank + '%'}
                                            </div>
                                        </div>
                                        <div>
                                            <h3 className='product-custom-header'>
                                                {typeof result.data.name !== 'object' ? result.data.name : result.data.name.value}
                                            </h3>
                                            <div className='app-criteria-item-details-specs'>
                                                {Object.keys(result.data).map((key) => {
                                                    if (!/^f\d+$/.test(key)) {
                                                        return null;
                                                    }
                                                    // @ts-ignore
                                                    const {name, value} = result.data[key];
                                                    return (
                                                        <div className='app-criteria-item-details-specs-spec'
                                                             key={result.id + key}>
                                                            <span>{name}</span>
                                                            <span>{value}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className='app-criteria-item-details-ctas'/>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const onVirtualAgentReadText = async (event: CustomEvent) => {
        const text = event.detail.text.trim();
        const shouldListen = event.detail?.shouldListen || false;
        const products = event.detail?.products || [];
        const options = event.detail?.options;
        isFirstQuestion.current = event.detail?.isFirstQuestion || false;
        const isLastQuestion = event.detail?.isLastQuestion || false;
        if (!text) {
            return;
        }

        setOptions([]);

        if (Array.isArray(products)) {
            setProducts(products);
        }

        question = text;
        console.log('Reading the text', question);
        readText(heyGenApiKeyRef.current, sessionInfoRef.current?.session_id, text)
            .then(response => {
                if (response && response.duration_ms && shouldListen) {
                    if (isLastQuestion) {
                        // the last step, no need to listen again
                        return;
                    }
                    setTimeout(startListening, response.duration_ms - 1000 || 5);
                    setTimeout(() => setOptions(options), 1000);

                    // track no action from user's side
                    // after reading the text wait for 15 seconds
                    setNoResponseTimeout(response.duration_ms + NO_RESPONSE_DEFAULT_TIMEOUT);
                }

                if (isLastQuestion) {
                    // the last step, no need to listen again
                    return setIsLastQuestionAsked(true);
                }
            })
            .catch(error => {
                console.log('Received the session error:', error);
            })
    }

    const onVirtualAgentStartListening = async () => {
        await startListening();
    }

    const onVirtualAgentStopListening = async (event: CustomEvent) => {
        const shouldFireRecordedEvent = event.detail.shouldFireRecordedEvent || false;
        const transcribedText = stopListening();

        if (shouldFireRecordedEvent && transcribedText) {
            // fire event to tell the recording has stopped
            document.dispatchEvent(new CustomEvent('virtual-agent-recorded', {
                detail: {
                    question,
                    answer: transcribedText
                }
            }));
        }
        question = '';
        transcription = '';
    }

    useEffect(() => {
        (async () => {
            // Automatically create a new session when the DOM is fully loaded
            if (!virtualAgentCreated.current) {
                virtualAgentCreated.current = true;
                const isSessionCreated = await createNewSession();
                setAgentIsReady(isSessionCreated);
            }
        })();

        // subscribe to virtual agent talk event
        // @ts-ignore
        document.addEventListener('virtual-agent-read', onVirtualAgentReadText);

        // subscribe to aws listen event
        // @ts-ignore
        document.addEventListener('virtual-agent-start-listening', onVirtualAgentStartListening);

        // subscribe to aws stop listening event
        // @ts-ignore
        document.addEventListener('virtual-agent-stop-listening', onVirtualAgentStopListening);

        return () => {
            // @ts-ignore
            document.removeEventListener('virtual-agent-read', onVirtualAgentReadText);
            // @ts-ignore
            document.removeEventListener('virtual-agent-start-listening', onVirtualAgentStartListening);
            // @ts-ignore
            document.removeEventListener('virtual-agent-stop-listening', onVirtualAgentStopListening);
            closeConnectionHandler();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const navigateToAppPage = useCallback(() => {
        stopListening();
        navigate(`/apps/${id}`, { replace: false });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (!isAgentReady || !areCandidateIcesReady) {
            return;
        }

        if (products) {
            // showing the results
            return clearTimeout(noResponseTimeoutRef.current);
        }

        clearTimeout(noResponseTimeoutRef.current);
        console.log('noResponseTimeoutRef update');
        noResponseTimeoutRef.current = setTimeout(navigateToAppPage, noResponseTimeout);

        return () => {
            clearTimeout(noResponseTimeoutRef.current);
            console.log('noResponseTimeoutRef unmount');
        };
    }, [noResponseTimeout, isAgentReady, areCandidateIcesReady, navigateToAppPage, products]);

    // Start session and display audio and video when clicking the "Start" button
    async function startAndDisplaySession() {
        if (!sessionInfoRef.current) {
            console.log('Please create a connection first');
            return false;
        }

        console.log('Starting session... please wait');

        const localDescription = await peerConnectionRef.current.createAnswer();
        await peerConnectionRef.current.setLocalDescription(localDescription);

        peerConnectionRef.current.onicecandidate = async ({ candidate }: any) => {
            if (candidate) {
                await handleICE(
                    heyGenApiKeyRef.current,
                    sessionInfoRef.current.session_id,
                    candidate.toJSON(),
                    candidateIceInProgressTasksCount,
                    setAreCandidateIcesReady,
                );
            }
        };

        peerConnectionRef.current.oniceconnectionstatechange = () => {
        };

        // Start session
        await startSession(heyGenApiKeyRef.current, sessionInfoRef.current.session_id, localDescription);

        let receivers = peerConnectionRef.current.getReceivers();
        receivers.forEach((receiver: any) => {
            receiver.jitterBufferTarget = 500;
        });

        console.log('Session started successfully');
        return true;
    }

    const createAgentNewSession = async (): Promise<any> => {
        let wasAgentStarted = false;
        heyGenApiKeyRef.current = heygenKeys.pop() || '';

        while (!wasAgentStarted) {
            const response = await newSession(heyGenApiKeyRef.current, 'low', avatarRef.current?.value || '', voiceRef.current?.value || '')
            if (!response) {
                // (() => setErrorMessage('This agent is busy now. Loading a new one for you...'))();
                const newKey = heygenKeys.pop();
                if (newKey) {
                    heyGenApiKeyRef.current = newKey;
                } else {
                    (() => setErrorMessage('Unfortunately all agents are busy now. Please try a little bit later.'))();
                    return null;
                }
            } else {
                setErrorMessage('');
                return response;
            }
        }

        return false;
    };

    async function closeConnectionHandler() {
        if (!sessionInfoRef.current) {
            return;
        }

        console.log('Closing connection... please wait');
        try {
            // Close local connection
            peerConnectionRef.current.close();
            // Call the close interface
            await stopSession(heyGenApiKeyRef.current, sessionInfoRef.current.session_id);

            // console.log(resp);
        } catch (err) {
            console.error('Failed to close the connection:', err);
        }
        console.log('Connection closed successfully');
    }

    const onLoadedmetadataHandler = () => {
        mediaElementRef.current?.play();
        renderCanvas(mediaElementRef, canvasElementRef);
    };

    const createTranscribeClient = () => {
        // @ts-ignore
        transcribeClient.current = new TranscribeStreamingClient({
            region: AWS_REGION,
            credentials: {
                accessKeyId: AWS_ACCESS_KEY_ID,
                secretAccessKey: AWS_SECRET_ACCESS_KEY,
            },
        });
    };

    const createAudioWorklet = async () => {
        if (!audioContext) {
            audioContext = new AudioContext();
        }

        if (audioContext.state === "suspended") {
            await audioContext.resume();
        }

        // TODO add an error handling
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

        await audioContext.audioWorklet.addModule('/processor.js')

        const node = new AudioWorkletNode(audioContext, 'my-processor');
        node.connect(audioContext.destination);

        workletNode = new AudioWorkletNode(audioContext, "my-processor");

        microphoneStream.current = audioContext.createMediaStreamSource(stream);
        microphoneStream.current?.connect(workletNode);

        setIsMicrophoneAccessible(true);
    };

    const startStreaming = async (language: LanguageCode, callback: (text: string) => void) => {
        const command = new StartStreamTranscriptionCommand({
            LanguageCode: language,
            MediaEncoding: "pcm",
            MediaSampleRateHertz: SAMPLE_RATE,
            AudioStream: getAudioStream(workletNode),
        });

        // @ts-ignore
        const data = await transcribeClient.current.send(command);

        const stream: MediaStream | undefined = microphoneStream.current?.mediaStream;

        if (!stream) {
            return;
        }

        monitorSilence(
            stream,
            microphoneStream.current?.context.state,
            audioContext,
            isFirstQuestion.current ? 5_000 : undefined
        );

        if (!data.TranscriptResultStream) {
            return;
        }

        for await (const event of data.TranscriptResultStream) {
            const results = event.TranscriptEvent?.Transcript?.Results;
            if (!results) {
                return;
            }

            if (results.length && !results[0]?.IsPartial) {
                const newTranscript = results[0].Alternatives?.[0].Transcript;
                callback(newTranscript + " ");
            }
        }
    };

    const startRecording = async (callback: (text: string) => void) => {
        if (!AWS_REGION || !AWS_ACCESS_KEY_ID || !AWS_SECRET_ACCESS_KEY) {
            alert("Set AWS env variables first.");
            return false;
        }

        if (microphoneStream.current || transcribeClient.current) {
            stopRecording();
        }

        await createAudioWorklet();
        createTranscribeClient();
        await startStreaming(language, callback);
    };

    const stopRecording = () => {
        if (microphoneStream.current) {
            microphoneStream.current.disconnect();
            microphoneStream.current = null;
        }

        if (workletNode) {
            workletNode.disconnect();
            workletNode = null;
        }

        if (audioContext) {
            audioContext.close();
            audioContext = null;
        }

        if (transcribeClient.current) {
            transcribeClient.current.destroy();
        }
    };

    const startListening = async () => {
        if (!isMicrophoneAccessible) {
            return;
        }

        isListeningInProgress.current = true;
        console.log('Listening');
        await startRecording((text) => {
            transcription += text;
            console.log(transcription);
            setNoResponseTimeout(NO_RESPONSE_DEFAULT_TIMEOUT++);
        });
    };

    const stopListening = (): string => {
        if (!isMicrophoneAccessible) {
            return '';
        }
        isListeningInProgress.current = false;

        stopRecording();
        return transcription.trim();
    };

    const shouldRenderOptions = options.length > 3;

    return (
        <div className='virtual-agent'>
            <div className="main">
                <h1>&nbsp;</h1>
                {errorMessage ?
                    <b className='error-message'>{errorMessage}</b>
                    : (<>
                            {isDecisionReady ? (
                                <h2>{appData?.name}</h2>
                            ) : (
                                <div>Loading the application for you...</div>
                            )}
                        </>
                    )
                }
                <div style={{display: isDecisionReady ? 'block' : 'none'}}>
                    <div className="actionRowsWrap">
                        <div className="actionRow">
                            <input id="avatarID" type="text" ref={avatarRef}/>
                            <input id="voiceID" type="text" ref={voiceRef}/>
                        </div>
                        <div className="actionRow">
                            {!isMicrophoneAccessible && (
                                <>
                                    <HeadsetOffIcon color='error'/>
                                    <div className='error-message'>
                                        We are unable to access your microphone. Please check the microphone
                                        permissions.
                                    </div>
                                </>
                            )}
                            {/*{isListeningInProgress.current && (*/}
                            {/*    <>*/}
                            {/*        <HeadsetMicIcon/>*/}
                            {/*        <div>I'm listening to you</div>*/}
                            {/*    </>*/}
                            {/*)}*/}
                        </div>
                    </div>

                    <div className={`videoSectionWrap ${shouldRenderOptions ? 'contains-options' : ''}`}>
                        <div className="videoWrap">
                            <video
                                id="mediaElement"
                                className="videoEle show"
                                autoPlay
                                ref={mediaElementRef}
                                onLoadedMetadata={onLoadedmetadataHandler}
                            ></video>
                            <canvas id="canvasElement" className="videoEle hide" ref={canvasElementRef}></canvas>
                        </div>

                        <ul className={`question-options ${!shouldRenderOptions ? 'hide' : ''}  `}>
                            {shouldRenderOptions ? options.map((item) => <li key={item}>{item}</li>) : null}
                        </ul>

                        <div className="actionRow switchRow hide" id="bgCheckboxWrap" ref={bgCheckboxWrapRef}>
                            <div className="switchWrap" style={{display: 'none'}}>
                                <span>Remove background</span>
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        id="removeBGCheckbox"
                                        onChange={(e) => setIsBackgroundRemoved(e.target.checked)}
                                        checked={isBackgroundRemoved}
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    {!errorMessage && <>
                        {(isAgentReady && areCandidateIcesReady && id) ?
                            <Questionnaire appId={+id} readyToStart={isReadyToStartTheSession}/>
                            : (<div>Loading...</div>)}
                    </>}
                    {isLastQuestionAsked ? (
                        <div style={{ textAlign: 'center', marginTop: 32 }}>
                            <Button onClick={() => navigate('/apps/' + id)}>
                                Check the {appData?.name}s page.
                            </Button>
                        </div>
                    ) : null}
                    {products ?
                        <div className='animate__tada'>
                            {renderProducts(products)}
                        </div>
                        : null
                    }
                </div>
            </div>
        </div>
    );
};

export default VirtualAgent;